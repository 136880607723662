import React, { useState } from 'react';

import { Container, LoginForm } from './styles';
import { Button, Grid, TextField } from '@material-ui/core';
import history from '../../../history';
import { error } from '../../../Components/Toast';
import { ToastContainer } from 'react-toastify';

import logo from '../../../Assets/logo.png';

import { useInfo } from '../../../Contexts/info.context';

import { adminLogin } from '../../../Services/api';

function Login() {
  const [name, setName] = useState('');
  const [pass, setPass] = useState('');

  const { setLogged } = useInfo();

  const attemptLogin = async() => {
    if(pass === '' || name === '') {
      error('😕 Preencha todos os campos', 'top-right');
    } else {
      const res = await adminLogin(name, pass);
      if(res.data.logged === true) {
        setLogged(true);
        history.push('/dashboard')
      } else {
        error('😕 Credenciais incorretas', 'top-right');
      }
    }
  }

  return(
    <Container className="login">
      <ToastContainer/>
      <Grid container justify="center" align="center" md={12} spacing={4}>
        <LoginForm>
          <img src={logo} width={350} style={{ marginBottom: 40 }} alt="logo" />
          <Grid item md={12}>
            <TextField
              id="outlined-basic" 
              label="Usuário" 
              variant="outlined" 
              value={name} 
              onChange={event => setName(event.target.value)}
              style={{ width: 300 }}
            />
          </Grid>
          <br/>
          <Grid item md={12}>
            <TextField 
              id="outlined-basic" 
              label="Senha" 
              variant="outlined" 
              type="password" 
              value={pass} 
              onChange={event => setPass(event.target.value)}
              style={{ width: 300 }}
            />
          </Grid>
          <br/><br/>

          <Button variant="contained" color="primary" onClick={attemptLogin}>
            Logar
          </Button>

        </LoginForm>
      </Grid>
    </Container>
  );
}

export default Login;
