import React from 'react';
import { FaArrowDown } from 'react-icons/fa';

import { Logo, Cellphone, TextContainer, Items, Text, Paragraph, Flags, About, Gallery, IconContainer, Contact } from './styles';

import logo from '../../../Assets/logo.png';
import appHome from '../../../Assets/AppHome.png';
import customQuadroo from '../../../Assets/CustomQuadroo.png';

import playstore from '../../../Assets/playstore.png';
import appstore from '../../../Assets/appstore.png';
import finished from '../../../Assets/Finished.png';

function Home() {
  return(
    <>
      <section id="download">
        <Items>
        <Logo>
          <img alt="" class="responsive" src={logo} width={400} />
        </Logo>

          <Cellphone>
            <img alt="" class="responsive2" src={customQuadroo} width={400} />
          </Cellphone>

          <TextContainer>
            <Text>Crie ja o seu Quadroo em nosso App!</Text>
            <br/>
            <Paragraph>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur porta dapibus nunc vel aliquam. Curabitur nunc lorem, rhoncus id venenatis ut, vulputate in neque. Interdum et malesuada fames ac ante ipsum primis in faucibus.</Paragraph>
            <br/>
          <Flags>
            <img alt="" src={playstore} class="flag" width={180} />
            <img alt="" src={appstore} class="flag" width={180} />
          </Flags>
            
          </TextContainer>
        </Items>
        <IconContainer>
          <FaArrowDown class="icon" color="white" size={50} />
        </IconContainer>
      </section>

      <section id="about">
        <About>
          <Text>Como funciona</Text>
          <br/>
          <Paragraph>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur porta dapibus nunc vel aliquam. Curabitur nunc lorem, rhoncus id venenatis ut, vulputate in neque. Interdum et malesuada fames ac ante ipsum primis in faucibus.</Paragraph>
          <br/><br/><br/>
          <Gallery>
            <img alt="" class="phones" src={appHome} width={360} />
            <img alt="" class="phones" src={customQuadroo} width={400} />
            <img alt="" class="phones" src={finished} width={373} />
          </Gallery>
        </About>
      </section>

      <section id="contact">
        <Contact>
          <Text color={'black'}>Contato</Text>
          <Paragraph color={'black'}>E-mail: quadroo@quadroo.com.br</Paragraph>
        </Contact>
      </section>
      <br/><br/><br/><br/><br/>

    </>
  );
}

export default Home;
