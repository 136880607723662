import Routes from './Routes/routes';
import { InfoContextProvider } from './Contexts/info.context';

function App() {
  return (
    <InfoContextProvider>
      <Routes />
    </InfoContextProvider>
  );
}

export default App;
