import styled from 'styled-components';

export const Logo = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  flex: 1;
  padding: 10px;
  box-shadow: 0px 0px 12px 5px rgba(0,0,0,0.38);
  background: white;
  margin-bottom: 5%;
  grid-area: l;
  width: 100%;
`;

export const Cellphone = styled.div`
  position: relative;
  right: 200px;
  @media (max-width: 700px) {
    right: 0;
  }
  grid-area: c;
`;

export const TextContainer = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  grid-area: t;
`;

export const Text = styled.h1`
  text-align: center;
  width: 50%;
  color: ${({ color }) => color || 'white' };
`;

export const Paragraph = styled.h3`
  color: ${({ color }) => color || 'white' };
  text-align: center;
  width: 50%;
`;

export const Items = styled.div`
  align-items: center;
  display: grid;
  grid-template-columns: repeat(2fr 1fr, minmax(240px, 1fr));
  grid-template-rows: repeat(60vh 0vh, minmax(240px, 1fr));
  grid-template-areas:  "l l l"
                        "t c c";
  @media (max-width: 700px) {
    grid-template-areas:
      'l'
      't'
      'c';
  }
`;

export const IconContainer = styled.div`
  margin-top: 5%;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

export const Flags = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
`;

export const About = styled.div`
  margin-top: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
`;

export const Contact = styled.div`
  margin-top: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
`;

export const Gallery = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  @media (max-width: 700px) {
    flex-direction: column;
  }
`;
