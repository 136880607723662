import api from './index';

// const headers = {
//   'Content-Type': 'application/json',
//   'access-token': await localStorage.getItem('token')
// }

export const checkEmail = async (email) => await api.post('/users/isemailavailable', { email });

export const getAddress = async () => await api.get('/add', {
  headers: {'access-token': await localStorage.getItem('@token')}
});

export const getPrice = async (id) => await api.get('/prices/' + id, {
  headers: {'access-token': await localStorage.getItem('@token')}
});

export const getUsers = async () => await api.get('/users/', {
  headers: {'access-token': await localStorage.getItem('@token')}
});

export const delUser = async(id) => api.delete('/users/' + id, {
  headers: {'access-token': await localStorage.getItem('@token')}
});

export const getOrders = async() => api.get('/orders/', {
  headers: {'access-token': await localStorage.getItem('@token')}
});

export const delOrder = async(id) => api.delete('/orders/delete/' + id, {
  headers: {'access-token': await localStorage.getItem('@token')}
});

export const getSkus = async() => api.get('/skus/', {
  headers: {'access-token': await localStorage.getItem('@token')}
});

export const getSku = async(id) => api.get('/skus/' + id, {
  headers: {'access-token': await localStorage.getItem('@token')}
});

export const delSku = async(id) => api.delete('/skus/delete/' + id, {
  headers: {'access-token': await localStorage.getItem('@token')}
});

export const getOrder = async (id) => await api.get('/orders/' + id, {
  headers: {'access-token': await localStorage.getItem('@token')}
});

export const getGroups = async () => await api.get('/groups/', {
  headers: {'access-token': await localStorage.getItem('@token')}
});

export const getGroup = async (id) => await api.get('/groups/' + id, {
  headers: {'access-token': await localStorage.getItem('@token')}
});

export const getPrices = async () => await api.get('/prices/', {
  headers: {'access-token': await localStorage.getItem('@token')}
});


export const adminLogin = async(user, pass) => {
  const response = await api.post('/admin/login', { user, pass })

  if (response.status >= 200 && response.status < 300) {
    await localStorage.setItem('@token', response.data.access_token);
    return response;
  }
};


export const getUserById = async (id) => await api.get('/users/' + id, {
  headers: {'access-token': await localStorage.getItem('@token')}
});

export const getUserByEmail = async (email) => await api.post('/users/findbyemail', { email });

export const login = async (email, pass) => {
  const response = await api.post('/users/login', { email, pass });

  if (response.status >= 200 && response.status < 300) {
    // console.log('TOKEN API: ', response.data.access_token)
    await localStorage.setItem('@token', response.data.access_token);

    // console.log('TOKEN:', await localStorage.getItem('@token'));

    return response;
  }
};

export const userReward = async(email) => {
  return await api.post('/users/reward/', {
    email
  }, {
    headers: {'access-token': await localStorage.getItem('@token')}
  });
}

export const updateUserRewarded = async(id, bool) => {
  return await api.put('/users/update/' + id, {
    "rewarded": bool
  }, {
    headers: {'access-token': await localStorage.getItem('@token')}
  });
}

export const updateAddress = async( userid, city, street, number, neighborhood, complement, postcode, state ) => {
  return await api.put('/address/update/' + userid, {
    state,
    city,
    postcode,
    street,
    neighborhood,
    complement,
    number
  }, {
    headers: {'access-token': await localStorage.getItem('@token')}
  });
}

export const createCustomer = async (
    firstname,
    lastname,
    email,
    phone,
    cpf,
    pass,
) => {
  const userResponse = await api.post('/users/create', {
    firstname,
    lastname,
    email,
    phone,
    cpf,
    pass,
    group: {
      id: 1
    }
  });

  const loginResponse = await api.post('/users/login', { email, pass })

  if (loginResponse.status >= 200 && loginResponse.status < 300) {
    await localStorage.setItem('@token', loginResponse.data.access_token);
  }

  return userResponse;
}

export const uploadImages = async(file, order, qtd, color, borderless, index) => {
  const form = new FormData;
  form.append('file', {
    uri: file,
    type: 'image/jpeg',
    name: 'profile-picture.jpg'
  });
  form.append("order", order);
  form.append("qtd", qtd);
  form.append("color", color);
  form.append("borderless", borderless)
  form.append("index", index);

  // console.log('BOrderless api: ', borderless)

  const response = await api.post('/users/upload', form);
  console.log('UPLOADIMAGES RESPONSE: ', response)

  return response;
}

export const createAddress = async(userid, city, street, number, neighborhood, complement, postcode, state, billing) => {
  const addressResponse = await api.post('/address/create', {
    state,
    city,
    postcode,
    street,
    neighborhood,
    complement,
    number,
    billing,
    user: {
      id: userid
    }
  }
  );

  return addressResponse;
}
  
export const changeAddress = async (city, street, number, neighborhood, complement, postcode, state) => {
  await api.post('/address/create', {
    state,
    city,
    postcode,
    street,
    neighborhood,
    complement,
    number
  });
}

export const updateOrder = async(orderId, grandTotal) => {
  await api.put('/orders/update/' + orderId, {
    "grand_total": grandTotal
  }, {
    headers: {'access-token': await localStorage.getItem('@token')}
  })
}

export const createOrder = async (
    // shipping_price,
    // shipping_cost, 
    // grand_total, 
    // subtotal,
    // discount,
    // coupon_name,
    user
  ) => {
    try {
      return await api.post('/orders/create', {
        shipping_price: 0,
        shipping_cost: 0, 
        grand_total: 0, 
        subtotal: 0,
        discount: 0,
        coupon_name: 'coupon',
        total_qtd: 0,
        user: {
          id: user
        }
      }, {
        headers: {'access-token': await localStorage.getItem('@token')}
      });
    } catch (error) {
      console.log('CREATE ORDER ERROR: ', error)
    }
}

export const createItems = async (
  images,
  orderId,
  userId,
) => {
  try {
    const response = await api.post('/items/create', {
      "images": images,
      "orderId": orderId,
      "userId": userId,
    }, {
      headers: {'access-token': await localStorage.getItem('@token')}
    });
    console.log('ITEMS RESPONSE: ', response.data)
    return response;
    
  } catch (error) {
    console.log('CREATE ITEMS ERROR: ', error)
  }
  // console.log(response)
}
