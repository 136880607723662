import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 1001px;
  background: #F27A88;
`;

export const LoginForm = styled.div`
  border-radius: 6px;
  background: white;
  width: 30%;
  padding: 2%;
  box-shadow: 9px 9px 14px 2px rgba(0,0,0,0.27);
`;
