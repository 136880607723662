import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 600px;
`;

export const CreateForm = styled.div`
  border-radius: 15px;
  background: white;
  width: 30%;
  padding: 2%;
  margin-bottom: 10%;
`;
