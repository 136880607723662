import React from 'react';
import { Router, Switch, Route, Redirect } from 'react-router-dom';

import Login from '../Pages/Admin/Login';
import Dashboard from '../Pages/Admin/AdminHome';
import Home from '../Pages/Customer/Home';

import history from '../history';
import Policy from '../Pages/Policy';

const Routes = () => {
  return (
    <Router history={history}>
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/login" component={Login} />
        <Route path="/dashboard" component={Dashboard} />
        <Route path="/policy" component={Policy} />
      </Switch>
    </Router>
  );
};

export default Routes;
