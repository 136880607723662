import React, { useEffect, useState } from 'react';

import { DataGrid } from '@material-ui/data-grid';
import { Button } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import FindInPage from '@material-ui/icons/FindInPage';
import Save from '@material-ui/icons/Save';

import CreateSku from '../CreateItem/skus';

import { success } from '../Toast/index';

import { ToastContainer } from 'react-toastify';

import { getSkus, delSku } from '../../Services/api';
import Inspect from '../Inspect';

function Skus() {
  const [rows, setRows] = useState([]);
  const [selected, setSelected] = useState(0);
  const [visible, setVisible] = useState(true);
  const [inspect, setInspect] = useState(false);

  const columns = [
    { field: 'id', headerName: 'ID', width: 70 },
    { field: 'sku', headerName: 'SKU', width: 130 },
    { field: 'weight', headerName: 'Weight', width: 130 },
    { field: 'height', headerName: 'Height', width: 200 },
    { field: 'width', headerName: 'Width', width: 200 },
    { field: 'length', headerName: 'Length', width: 130 },
  ];

  const deleteSku = async() => {
    await delSku(selected);
    const res = await getSkus();
    setRows(res.data)
    success('😄 SKU deletado com sucesso!', 'top-right');
  }
  
  useEffect(() => {
    const fetchData = async() => {
      const res = await getSkus();
      setRows(res.data)
    }
    fetchData();
  }, [])
  
  return(
    <>
      {visible ?
      <>
      {inspect ? 
        <Inspect setVisible={setInspect} type={'sku'} id={selected} />
      :
        <>
        <ToastContainer/>
        <h3>Skus</h3>
        <div style={{ height: 400, width: '100%' }}>
          <DataGrid 
          rows={rows} 
          columns={columns} 
          pageSize={5} 
          checkboxSelection 
          onSelectionChange={(value) => {
            setSelected(value.rowIds);
          }} 
          />
        </div>
        <br/>
        <Button 
          style={{ marginRight: 5, marginBottom: 5 }} 
          variant="contained" 
          color="secondary" 
          onClick={deleteSku}
          startIcon={<DeleteIcon />}
        >
          Deletar
        </Button>

        <Button 
          style={{ marginBottom: 5, marginRight: 5 }} 
          variant="contained" 
          color="primary" 
          onClick={() => setInspect(true)}
          startIcon={<FindInPage/>}
        >
          Inspecionar
        </Button>

        <Button 
          style={{ marginBottom: 5 }} 
          variant="contained" 
          color="primary" 
          startIcon={<Save/>}
          onClick={() => setVisible(false)}
        >
          Criar
        </Button>
        </>
      }
      </> 
      : 
        <CreateSku setVisible={setVisible} />
      }
    </>
  );
}

export default Skus;
