import styled from 'styled-components';
import ListItemIcon from '@material-ui/core/ListItemIcon';

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 6fr;
  grid-template-rows: 105vh 0vh;
  grid-template-areas: "d c";
`;

export const DrawerContainer = styled.div`
  text-align: center;
  background: rgb(241,114,128);
  grid-area: d;
  height: 100%;
  margin-top: -5px;
  margin-left: -10px;
  box-shadow: -9px 8px 13px 0px rgba(0,0,0,0.50);
`;

export const ContentContainer = styled.div`
  background: #f4f4f4;
  grid-area: c;
  padding: 2%;
`;

export const Icon = styled(ListItemIcon)`
  fill: white;
`;
