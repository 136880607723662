import React, { useEffect, useState } from 'react';

import { DataGrid } from '@material-ui/data-grid';
import { Button } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import FindInPage from '@material-ui/icons/FindInPage';

import { success } from '../Toast/index';

import { ToastContainer } from 'react-toastify';

import { getPrices, delUser } from '../../Services/api';
import { Save } from '@material-ui/icons';
import Inspect from '../Inspect';

function Prices() {
  const [rows, setRows] = useState([]);
  const [selected, setSelected] = useState(0);
  const [visible, setVisible] = useState(false);

  const columns = [
    { field: 'id', headerName: 'ID', width: 70 },
    { field: 'price', headerName: 'Price', width: 130 },
    { field: 'sale_price', headerName: 'Sale Price', width: 130 },
  ];

  const deleteUser = async() => {
    await delUser(selected);
    const res = await getPrices();
    setRows(res.data)
    success('😄 Usuario deletado com sucesso!', 'top-right');
  }
  
  useEffect(() => {
    const fetchData = async() => {
      const res = await getPrices();
      setRows(res.data);
    }
    fetchData();
  }, [])

  return(
    <>
    {visible ? <Inspect setVisible={setVisible} type={'price'} id={selected} /> :
    <>
      <ToastContainer/>
      <h3>Prices</h3>
      <div style={{ height: 400, width: '100%' }}>
        <DataGrid 
        rows={rows} 
        columns={columns} 
        pageSize={5} 
        checkboxSelection 
        onSelectionChange={(value) => {
          setSelected(value.rowIds);
        }} 
        />
      </div>
      <br/>
      <Button 
        style={{ marginRight: 5, marginBottom: 5 }} 
        variant="contained" 
        color="secondary" 
        onClick={deleteUser}
        startIcon={<DeleteIcon />}
      >
        Deletar
      </Button>
      <Button 
        style={{ marginBottom: 5, marginRight: 5 }} 
        variant="contained" 
        color="primary" 
        onClick={() => setVisible(true)}
        startIcon={<FindInPage/>}
      >
        Inspecionar
      </Button>

      <Button 
      style={{ marginBottom: 5 }} 
      variant="contained" 
      color="primary" 
      onClick={() => setVisible(true)}
      startIcon={<Save/>}
      >
      Criar
      </Button>
    </>
    }
    </>

  );
}

export default Prices;
