import React, { useEffect, useState } from 'react';

import { getOrders } from '../../Services/api';

import {
  AreaChart, 
  Area,
  XAxis, 
  YAxis, 
  CartesianGrid, 
  Tooltip,
  BarChart,
  Legend,
  Bar
} from 'recharts';

import { Graph, Row } from './styles';
import { fetchOrders, fetchRevenue } from '../../Services/graphData';

function Home() {
  const [orderData, setOrderData] = useState(null);
  const [revenue, setRevenue] = useState(null);
  
  useEffect(() => {
    const fetchDatas = async() => {
      await fetchOrders(getOrders, setOrderData);
      await fetchRevenue(getOrders, setRevenue);
    };
    fetchDatas();
  }, [])

  return(
    <>
      <Row>
        <Graph width={50}>
        <p>Vendas</p>
        <br/>
          <AreaChart
            width={700}
            height={280}
            data={orderData}
          >
            <CartesianGrid strokeDasharray="4 4" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Area type="monotone" dataKey="Vendas" stroke="#f18480" fill="#f18480" />
          </AreaChart>
        </Graph>

        <Graph width={50}>
          <p>Lucro</p>
          <br/>
          <BarChart width={700} height={300} data={revenue}>
            <CartesianGrid strokeDasharray="4 4" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="Lucro" stroke="#f18480" fill="#f1848090" />
          </BarChart>
        </Graph>
      </Row>
    </>
  );
}

export default Home;
