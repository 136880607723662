import React, { useEffect, useState } from 'react';

import { Container, CreateForm } from './styles';
import { Button, Grid, TextField } from '@material-ui/core';
import { error, success } from '../../Components/Toast';
import { ToastContainer } from 'react-toastify';

import { Cancel, Save } from '@material-ui/icons';

function CreateSku({setVisible}) {
  const [sku, setSku] = useState('');
  const [weight, setWeight] = useState(0);
  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);
  const [length, setLength] = useState(0);

  const handleCreate = async() => {
    success('😄 SKU criado com sucesso!', 'top-right');
  }

  return(
    <>
    <h3>Skus</h3>
    <ToastContainer/>
    <Container>
      <Grid container justify="center" align="center" md={12} spacing={4}>
        <CreateForm>
          <Grid item md={12}>
            <TextField 
            id="outlined-basic" 
            label="SKU" 
            variant="outlined"
            fullWidth
            value={sku} 
            onChange={event => setSku(event.target.value)} 
          />
          </Grid>
          <br/>
          <Grid item md={12}>
            <TextField 
            id="outlined-basic" 
            label="Weight"
            type="number"
            variant="outlined" 
            value={weight} 
            onChange={event => setWeight(event.target.value)} 
            fullWidth
            />
          </Grid>
          <br/>
          <Grid item md={12}>
            <TextField 
            id="outlined-basic" 
            label="Height"
            type="number"
            variant="outlined" 
            value={height} 
            onChange={event => setHeight(event.target.value)} 
            fullWidth
            />
          </Grid>
          <br/>
          <Grid item md={12}>
            <TextField 
            id="outlined-basic" 
            label="Width"
            type="number"
            variant="outlined" 
            value={width} 
            onChange={event => setWidth(event.target.value)} 
            fullWidth
            />
          </Grid>
          <br/>
          <Grid item md={12}>
            <TextField 
            id="outlined-basic" 
            label="Length"
            type="number"
            variant="outlined" 
            value={length} 
            onChange={event => setLength(event.target.value)} 
            fullWidth
            />
          </Grid>
          <br/>

          <Button startIcon={<Save />} style={{ marginRight: 5 }} variant="contained" color="primary" onClick={handleCreate}>
            Criar
          </Button>

          <Button startIcon={<Cancel />} variant="contained" color="secondary" onClick={() => setVisible(true)}>
            Voltar
          </Button>

        </CreateForm>
      </Grid>
    </Container>
    </>
  );
}

export default CreateSku;
