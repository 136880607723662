import React, { useEffect, useState } from 'react';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { error } from '../../../Components/Toast';
import { ToastContainer } from 'react-toastify';

import history from '../../../history';
import { useInfo } from '../../../Contexts/info.context';

import Main from '../../../Components/Menus/home';
import Users from '../../../Components/Menus/users';
import Orders from '../../../Components/Menus/orders';
import Skus from '../../../Components/Menus/skus';
import Groups from '../../../Components/Menus/groups';

import logo from '../../../Assets/logoOnly.png';

import { ContentContainer, DrawerContainer, Container } from './styles';

import { PeopleAltRounded, ShoppingCart, ViewWeek, Group, Home as HomeIcon, ExitToApp, MonetizationOn } from '@material-ui/icons/';
import Prices from '../../../Components/Menus/prices';

function Home() {
  const [item, setItem] = useState('Home');

  const { logged } = useInfo();

  useEffect(() => {
    onLoad();
  }, []);

  async function onLoad() {
    try {
      if(logged === false) {
        history.push('/');
      }
    } catch (e) {
      alert(e);
    }
  }

  return(
    <Container>
      <DrawerContainer>
      <img src={logo} width={40} style={{ marginTop: 10 }} />
      <List>
        <ListItem onClick={() => setItem('Home')} button key={'Home'}>
          <ListItemIcon><HomeIcon style={ item === 'Home' ? {fill: 'white'} : {fill: '#161A20'}} /></ListItemIcon>
          <ListItemText style={ item === 'Home' ? {color: 'white'} : {color: '#161A20'}} primary={'Home'} />
        </ListItem>

        <ListItem onClick={() => setItem('Users')} button key={'Usuários'}>
          <ListItemIcon><PeopleAltRounded style={ item === 'Users' ? {fill: 'white'} : {fill: '#161A20'}} /></ListItemIcon>
          <ListItemText style={ item === 'Users' ? {color: 'white'} : {color: '#161A20'}} primary={'Usuários'} />
        </ListItem>

        <ListItem onClick={() => setItem('Orders')} button key={'Pedidos'}>
          <ListItemIcon><ShoppingCart style={ item === 'Orders' ? {fill: 'white'} : {fill: '#161A20'}} /></ListItemIcon>
          <ListItemText style={ item === 'Orders' ? {color: 'white'} : {color: '#161A20'}} primary={'Pedidos'} />
        </ListItem>

        <ListItem onClick={() => setItem('Skus')} button key={'Skus'}>
          <ListItemIcon><ViewWeek style={ item === 'Skus' ? {fill: 'white'} : {fill: '#161A20'}} /></ListItemIcon>
          <ListItemText style={ item === 'Skus' ? {color: 'white'} : {color: '#161A20'}} primary={'Skus'} />
        </ListItem>

        <ListItem onClick={() => setItem('Groups')} button key={'Grupos'}>
          <ListItemIcon><Group style={ item === 'Groups' ? {fill: 'white'} : {fill: '#161A20'}}  /></ListItemIcon>
          <ListItemText style={ item === 'Groups' ? {color: 'white'} : {color: '#161A20'}} primary={'Grupos'} />
        </ListItem>

        <ListItem onClick={() => setItem('Prices')} button key={'Prices'}>
          <ListItemIcon><MonetizationOn style={ item === 'Prices' ? {fill: 'white'} : {fill: '#161A20'}}  /></ListItemIcon>
          <ListItemText style={ item === 'Prices' ? {color: 'white'} : {color: '#161A20'}} primary={'Preços'} />
        </ListItem>

        <Divider style={{ background: '#ffffff90' }} />

        <ListItem button key={'Sair'} onClick={() => history.push('/')}>
          <ListItemIcon><ExitToApp style={{ fill: '#161A20' }} /></ListItemIcon>
          <ListItemText style={{color: '#161A20'}} primary={'Sair'} />
        </ListItem>

      </List>
    </DrawerContainer>

    <ContentContainer>
      {item == 'Home' ? <Main/> : null}
      {item == 'Users' ? <Users/> : null}
      {item == 'Orders' ? <Orders/> : null}
      {item == 'Skus' ? <Skus/> : null}
      {item == 'Groups' ? <Groups/> : null}
      {item == 'Prices' ? <Prices/> : null}
    </ContentContainer>

    </Container>
  );
}

export default Home;
