import React, { useEffect, useState } from 'react';

import { DataGrid } from '@material-ui/data-grid';
import { Button } from '@material-ui/core';
import FindInPage from '@material-ui/icons/FindInPage';

import { success } from '../Toast/index';

import { ToastContainer } from 'react-toastify';

import DeleteIcon from '@material-ui/icons/Delete';

import { getOrders, delOrder } from '../../Services/api';
import Inspect from '../Inspect';

function Orders() {
  const [rows, setRows] = useState([]);
  const [selected, setSelected] = useState(0);
  const [visible, setVisible] = useState(false);

  const columns = [
    { field: 'id', headerName: 'ID', width: 70 },
    { field: 'payment_status', headerName: 'Payment Status', width: 130 },
    { field: 'shipping_price', headerName: 'Shipping Price', width: 130 },
    { field: 'shipping_cost', headerName: 'Shipping Cost', width: 200 },
    { field: 'grand_total', headerName: 'Grand Total', width: 130 },
    { field: 'discount', headerName: 'Discount', width: 130 },
    { field: 'coupon_name', headerName: 'Coupon', width: 130 },
    { field: 'shipping_address', headerName: 'Shipping Address', width: 130 },
    { field: 'billing_address', headerName: 'Billing Address', width: 130 },
  ];

  const deleteOrder = async() => {
    await delOrder(selected);
    const res = await getOrders();
    setRows(res.data)
    success('😄 Pedido deletado com sucesso!', 'top-right');
  }
  
  useEffect(() => {
    const fetchData = async() => {
      const res = await getOrders();
      setRows(res.data)
    }
    fetchData();
  }, [])

  return(
    <>
    {visible ? <Inspect setVisible={setVisible} type={'orders'} id={selected} /> :
    <>
      <ToastContainer/>
      <h3>Pedidos</h3>
      <div style={{ height: 400, width: '100%' }}>
        <DataGrid 
        rows={rows} 
        columns={columns} 
        pageSize={5} 
        checkboxSelection 
        onSelectionChange={(value) => {
          setSelected(value.rowIds);
        }} 
        />
      </div>
      <br/>
      <Button 
        style={{ marginRight: 5, marginBottom: 5 }} 
        variant="contained" 
        color="secondary" 
        onClick={deleteOrder}
        startIcon={<DeleteIcon />}
      >
        Deletar
      </Button>
      <Button 
        style={{ marginBottom: 5 }} 
        variant="contained" 
        color="primary" 
        onClick={() => setVisible(true)}
        startIcon={<FindInPage />}
      >
        Inspecionar
      </Button>
    </>
    }
    </>
  );
}

export default Orders;
