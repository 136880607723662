import React, { useEffect, useState } from 'react';

import { DataGrid } from '@material-ui/data-grid';
import { Button } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import FindInPage from '@material-ui/icons/FindInPage';

import { success } from '../Toast/index';

import { ToastContainer } from 'react-toastify';

import { getUsers, delUser } from '../../Services/api';
import Inspect from '../Inspect';

function Users() {
  const [rows, setRows] = useState([]);
  const [selected, setSelected] = useState(0);
  const [visible, setVisible] = useState(false);

  const columns = [
    { field: 'id', headerName: 'ID', width: 70 },
    { field: 'firstname', headerName: 'First name', width: 130 },
    { field: 'lastname', headerName: 'Last name', width: 130 },
    { field: 'email', headerName: 'Email', width: 200 },
    { field: 'phone', headerName: 'Phone Number', width: 130 },
    { field: 'cpf', headerName: 'CPF', width: 140 },
    { field: 'balance', headerName: 'Balance', width: 100 },
  ];

  const inspect = async() => {
    setVisible(true);
  }

  const deleteUser = async() => {
    await delUser(selected);
    const res = await getUsers();
    setRows(res.data)
    success('😄 Usuario deletado com sucesso!', 'top-right');
  }
  
  useEffect(() => {
    const fetchData = async() => {
      const res = await getUsers();
      setRows(res.data)
    }
    fetchData();
  }, [])
  
  return(
    <>
      {visible ? <Inspect setVisible={setVisible} type={'user'} id={selected} /> :
      <>
        <ToastContainer/>
        <h3>Usuários</h3>
        <div style={{ height: 400, width: '100%' }}>
          <DataGrid 
          rows={rows} 
          columns={columns} 
          pageSize={5} 
          checkboxSelection 
          onSelectionChange={(value) => {
            setSelected(value.rowIds);
          }} 
          />
        </div>
        <br/>
        <Button 
          style={{ marginRight: 5, marginBottom: 5 }} 
          variant="contained" 
          color="secondary" 
          onClick={deleteUser}
          startIcon={<DeleteIcon />}
        >
          Deletar
        </Button>
        <Button 
          style={{ marginBottom: 5 }} 
          variant="contained" 
          color="primary" 
          onClick={inspect}
          startIcon={<FindInPage/>}
        >
          Inspecionar
        </Button>
      </>
      }
    </>
  );
}

export default Users;
