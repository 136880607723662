import React, { useEffect, useState } from 'react';

import { Container, CreateForm } from './styles';
import { Button, Grid, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';

import { success } from '../../Components/Toast';
import { ToastContainer } from 'react-toastify';

import Lottie from 'react-lottie';

import animationData from '../../Assets/loading.json';

import { Cancel, Save } from '@material-ui/icons';
import { getUserById, getSku, getOrder, getGroup, getPrice, getGroups } from '../../Services/api';
import { DataGrid } from '@material-ui/data-grid';
import { AddressCols, OrderCols, PriceCols, ItemCols } from '../../Helpers/cols';

function Inspect({ setVisible, type, id }) {

  const [data, setData] = useState(null);
  const [item, setItem] = useState('');
  const [open, setOpen] = useState(false);
  const [groups, setGroups] = useState(null);

  const handleCreate = async() => {
    success('😄 Usuario alterado com sucesso!', 'top-right');
  }

  useEffect(() => {
    const fetchData = async() => {
      let res;

      const gropRes = await getGroups()
      setGroups(gropRes.data)

      switch (type) {
        case 'user':
          res = await getUserById(id);
          setData(res.data[0]);
          break;
  
        case 'sku':
          res = await getSku(id);
          setData(res.data[0]);
          break;
  
        case 'orders':
          res = await getOrder(id);
          setData(res.data[0]);
          break;
  
        case 'groups':
          res = await getGroup(id);
          setData(res.data[0]);
          break;
  
        case 'price':
          res = await getPrice(id);
          setData(res.data[0]);
          break;  
  
        default:
          break;
      }
    }

    fetchData();
  }, [])

  const handleChange = (e) => {
    setData((initial) => {
      let temp = { ...initial };
      temp[e.target.name] = e.target.value;
      return temp;
    });
  };


  const isDisabled = (key) => {
    if(key === 'id')         { return true }
    if(key === 'pass')       { return true }
    if(key === 'createdAt')  { return true }
    if(key === 'updatedAt')  { return true }
  }
  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const formatedName = type.charAt(0).toUpperCase() + type.slice(1);

  const defaultOptions = {
    loop: true,
    autoplay: true, 
    animationData: animationData,
  };

  const renderDataGrid = (dataKey, dataValue) => {
    console.log(dataKey)
    if(dataKey !== 'group') {
      if(typeof dataValue !== 'object') {
        return(
          <TextField 
              name={dataKey}
              id="outlined-basic" 
              label={dataKey}
              value={dataValue}
              variant="outlined"
              fullWidth
              disabled={isDisabled(dataKey)}
              onChange={handleChange}
          />
        )
      } else {
        console.log('JHONNYBIR: ', dataKey)
        switch (dataKey) {
          case 'address':
            return(
              <>
              <p>Address</p>
              <br/>
              <div style={{ height: 400, width: '100%' }}>
                <DataGrid 
                  rows={dataValue} 
                  columns={AddressCols} 
                  pageSize={5}
                />
              </div>
              </>
            )

          case 'shipping_address':
            return(
              <>
              <p>Shipping Address</p>
              <br/>
              <div style={{ height: 400, width: '100%' }}>
                <DataGrid 
                  rows={dataValue} 
                  columns={AddressCols} 
                  pageSize={5}
                />
              </div>
              </>
            )

          case 'billing_address':
            return(
              <>
              <p>Shipping Address</p>
              <br/>
              <div style={{ height: 400, width: '100%' }}>
                <DataGrid 
                  rows={dataValue} 
                  columns={AddressCols} 
                  pageSize={5}
                />
              </div>
              </>
            )

          case 'orders':
            return(
              <>
              <p>Orders</p>
              <br/>
              <div style={{ height: 400, width: '100%' }}>
                <DataGrid 
                  rows={dataValue} 
                  columns={OrderCols} 
                  pageSize={5}
                />
              </div>
              </>
            );

          case 'price':
            return(
              <>
              <p>Prices</p>
              <br/>
              <div style={{ height: 400, width: '100%' }}>
                <DataGrid 
                  rows={dataValue} 
                  columns={PriceCols} 
                  pageSize={5}
                />
              </div>
              </>
            );

          case 'items':
            return(
              <>
              <p>Items</p>
              <br/>
              <div style={{ height: 400, width: '100%' }}>
                <DataGrid 
                  rows={dataValue} 
                  columns={ItemCols} 
                  pageSize={5}
                />
              </div>
              </>
            );
          default:
            break;
        }
      }
    } else {
      return(
        <>
          <InputLabel id="demo-controlled-open-select-label">{dataKey}</InputLabel>
          <Select
            labelId="demo-controlled-open-select-label"
            id="demo-controlled-open-select"
            open={open}
            onClose={handleClose}
            onOpen={handleOpen}
            value={item || dataValue.id}
            fullWidth
            onChange={(e) => setItem(e.target.value)}
          >
            {
              groups.map((group) => (
                <MenuItem key={group.id} value={group.id}>{group.name}</MenuItem>
              ))
            }
          </Select>
        </>
      )
    }
  }

  return(
    <>
      <h3>{formatedName}</h3>
      <p>Here you can check {formatedName}'s info and update it if you want </p>
      <br/>
      <ToastContainer/>
      <Container>
          <CreateForm>

            {
              data && (
                Object.entries(data).map(([key, value]) => {
                  return (
                    <>
                      <br/>
                      <Grid item md={12}>
                        {renderDataGrid(key, value)}
                      </Grid>
                    </>
                  )
                })
              )
            }

            {
              !data && (
                <Lottie
                  options={defaultOptions}
                  height={300}
                  width={300}
                />
              )
            }

            <br/>
            <Button startIcon={<Save />} style={{ marginRight: 5 }} variant="contained" color="primary" onClick={handleCreate}>
              Salvar
            </Button>

            <Button startIcon={<Cancel />} variant="contained" color="secondary" onClick={() => setVisible(false)}>
              Voltar
            </Button>

          </CreateForm>
      
      </Container>
    </>
  );
}

export default Inspect;
