export const AddressCols = [
  { field: 'id', headerName: 'ID', width: 70 },
  { field: 'state', headerName: 'State', width: 70 },
  { field: 'city', headerName: 'City', width: 130 },
  { field: 'postcode', headerName: 'Postcode', width: 120 },
  { field: 'street', headerName: 'Street', width: 200 },
  { field: 'neighborhood', headerName: 'Neighborhood', width: 200 },
  { field: 'complement', headerName: 'Complement', width: 120 },
  { field: 'number', headerName: 'Number', width: 100 },
  { field: 'billing', headerName: 'Biling', width: 100 },
];

export const OrderCols = [
  { field: 'id', headerName: 'ID', width: 70 },
  { field: 'payment_status', headerName: 'Payment Status', width: 140 },
  { field: 'shipping_price', headerName: 'Shipping Price', width: 130 },
  { field: 'shipping_cost', headerName: 'Shipping Cost', width: 130 },
  { field: 'grand_total', headerName: 'Grand Total', width: 130 },
  { field: 'subtotal', headerName: 'Subtotal', width: 120 },
  { field: 'discount', headerName: 'Discount', width: 100 },
  { field: 'coupon_name', headerName: 'Coupon Name', width: 130 },
  { field: 'total_qtd', headerName: 'Item quantity', width: 130 },
];

export const PriceCols = [
  { field: 'id', headerName: 'ID', width: 70 },
  { field: 'price', headerName: 'Price', width: 140 },
  { field: 'sale_price', headerName: 'Sale Price', width: 130 },
];

export const ItemCols = [
  { field: 'id', headerName: 'ID', width: 70 },
  { field: 'qtd', headerName: 'Quantity', width: 140 },
  { field: 'item_base_price', headerName: 'Base Price', width: 130 },
  { field: 'item_sale_price', headerName: 'Sale Price', width: 130 },
  { field: 'item_total', headerName: 'Total', width: 130 }
];
