import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 600px;
`;

export const CreateForm = styled.div`
  margin-top: 20%;
  border-radius: 15px;
  background: white;
  width: 80%;
  padding: 2%;
  align-items: center;
  justify-content: center;
  height: 800px;
  overflow-x: scroll;
`;
