import styled from 'styled-components';

export const Graph = styled.div`
  background: white;
  width: ${({ width }) => width + '%' };
  padding: 30px;
  box-shadow: 10px 12px 18px 2px rgba(0,0,0,0.33);
  border-radius: 10px;
  margin: 10px;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`;
