import React, { createContext, useContext, useState, useEffect } from 'react';

const InfoContext = createContext(null);

export const useInfo = () => useContext(InfoContext);

export const InfoContextProvider = ({ children }) => {
  
  const [logged, setLogged] = useState(false);

  return (
    <InfoContext.Provider
      value={{
        logged,
        setLogged
      }}
    >
      {children}
    </InfoContext.Provider>
  );
}
