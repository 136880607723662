export const fetchOrders = async(getOrders, setData) => {
  
  const orders = await getOrders();

  const newData = [
    {
      name: 'Janeiro', Vendas: orders.data.reduce((sum,order)=>{
        if(order.createdAt[5] + order.createdAt[6] === '01') {
          
           sum+= order.total_qtd
        } 
        return sum
      },0)
    },
    {
      name: 'Fevereiro', Vendas: orders.data.reduce((sum,order)=>{
        if(order.createdAt[5] + order.createdAt[6] === '02') {
          
           sum+= order.total_qtd
        } 
        return sum
      },0)
    },
    {
      name: 'Março', Vendas: orders.data.reduce((sum,order)=>{
        if(order.createdAt[5] + order.createdAt[6] === '03') {
          
           sum+= order.total_qtd
        } 
        return sum
      },0)
    },
    {
      name: 'Abril', Vendas: orders.data.reduce((sum,order)=>{
        if(order.createdAt[5] + order.createdAt[6] === '04') {
          
           sum+= order.total_qtd
        } 
        return sum
      },0)
    },
    {
      name: 'Maio', Vendas: orders.data.reduce((sum,order)=>{
        if(order.createdAt[5] + order.createdAt[6] === '05') {
          
           sum+= order.total_qtd
        } 
        return sum
      },0)
    },
    {
      name: 'Junho', Vendas: orders.data.reduce((sum,order)=>{
        if(order.createdAt[5] + order.createdAt[6] === '06') {
          
           sum+= order.total_qtd
        } 
        return sum
      },0)
    },
    {
      name: 'Julho', Vendas: orders.data.reduce((sum,order)=>{
        if(order.createdAt[5] + order.createdAt[6] === '07') {
          
           sum+= order.total_qtd
        } 
        return sum
      },0) 
    },
    {
      name: 'Agosto', Vendas: orders.data.reduce((sum,order)=>{
        if(order.createdAt[5] + order.createdAt[6] === '08') {
          
           sum+= order.total_qtd
        } 
        return sum
      },0) 
    },
    {
      name: 'Setembro', Vendas: orders.data.reduce((sum,order)=>{
        if(order.createdAt[5] + order.createdAt[6] === '09') {
          
           sum+= order.total_qtd
        } 
        return sum
      },0)
    },
    {
      name: 'Outubro', Vendas: orders.data.reduce((sum,order)=>{
        if(order.createdAt[5] + order.createdAt[6] === '10') {
          
           sum+= order.total_qtd
        } 
        return sum
      },0)
    },
    {
      name: 'Novembro', Vendas: orders.data.reduce((sum,order)=>{
        if(order.createdAt[5] + order.createdAt[6] === '11') {
          
           sum+= order.total_qtd
        } 
        return sum
      },0)
    },
    {
      name: 'Dezembro', Vendas: orders.data.reduce((sum,order)=>{
        if(order.createdAt[5] + order.createdAt[6] === '12') {
          
           sum+= order.total_qtd
        } 
        return sum
      },0)
    },
  ];

  setData(newData)
}

export const fetchRevenue = async(getOrders, setData) => {
  
  const orders = await getOrders();

  const newData = [
    {
      name: 'Janeiro', Lucro: orders.data.reduce((sum,order)=>{
        if(order.createdAt[5] + order.createdAt[6] === '01') {
          
           sum+= parseInt(order.grand_total - order.shipping_price)
        } 
        return sum
      },0)
    },
    {
      name: 'Fevereiro', Lucro: orders.data.reduce((sum,order)=>{
        if(order.createdAt[5] + order.createdAt[6] === '02') {
          
           sum+= parseInt(order.grand_total - order.shipping_price)
        } 
        return sum
      },0)
    },
    {
      name: 'Março', Lucro: orders.data.reduce((sum,order)=>{
        if(order.createdAt[5] + order.createdAt[6] === '03') {
          
           sum+= parseInt(order.grand_total - order.shipping_price)
        } 
        return sum
      },0)
    },
    {
      name: 'Abril', Lucro: orders.data.reduce((sum,order)=>{
        if(order.createdAt[5] + order.createdAt[6] === '04') {
          
           sum+= parseInt(order.grand_total - order.shipping_price)
        } 
        return sum
      },0)
    },
    {
      name: 'Maio', Lucro: orders.data.reduce((sum,order)=>{
        if(order.createdAt[5] + order.createdAt[6] === '05') {
          
           sum+= parseInt(order.grand_total - order.shipping_price)
        } 
        return sum
      },0)
    },
    {
      name: 'Junho', Lucro: orders.data.reduce((sum,order)=>{
        if(order.createdAt[5] + order.createdAt[6] === '06') {
          
           sum+= parseInt(order.grand_total - order.shipping_price)
        } 
        return sum
      },0)
    },
    {
      name: 'Julho', Lucro: orders.data.reduce((sum,order)=>{
        if(order.createdAt[5] + order.createdAt[6] === '07') {
          
           sum+= parseInt(order.grand_total - order.shipping_price)
        } 
        return sum
      },0) 
    },
    {
      name: 'Agosto', Lucro: orders.data.reduce((sum,order)=>{
        if(order.createdAt[5] + order.createdAt[6] === '08') {
          
           sum+= parseInt(order.grand_total - order.shipping_price)
        } 
        return sum
      },0) 
    },
    {
      name: 'Setembro', Lucro: orders.data.reduce((sum,order)=>{
        if(order.createdAt[5] + order.createdAt[6] === '09') {
          
           sum+= parseInt(order.grand_total - order.shipping_price)
        } 
        return sum
      },0)
    },
    {
      name: 'Outubro', Lucro: orders.data.reduce((sum,order)=>{
        if(order.createdAt[5] + order.createdAt[6] === '10') {
          
           sum+= parseInt(order.grand_total - order.shipping_price)
        } 
        return sum
      },0)
    },
    {
      name: 'Novembro', Lucro: orders.data.reduce((sum,order)=>{
        if(order.createdAt[5] + order.createdAt[6] === '11') {
          
           sum+= parseInt(order.grand_total - order.shipping_price)
        } 
        return sum
      },0)
    },
    {
      name: 'Dezembro', Lucro: orders.data.reduce((sum,order)=>{
        if(order.createdAt[5] + order.createdAt[6] === '12') {
          
           sum+= parseInt(order.grand_total - order.shipping_price)
        } 
        return sum
      },0)
    },
  ];

  setData(newData)
}

